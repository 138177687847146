.btn.btn-outline-dark:disabled {
    background: grey;
    border: grey;
    color: white;
}

.passwordIcon {
    position: absolute;
    right: 13px;
    top: 37px;
    cursor: pointer;
}

.passwordIconError {
    position: absolute;
    right: 36px;
    top: 38px;
    cursor: pointer;
}

.form-control:focus,
.form-select:focus {
    border-top: 1px solid $grey !important;
    border-left: 1px solid $grey !important;
    border-right: 1px solid $grey !important;
    border-bottom: 4px solid $primary !important;
}

.light-border {
    border: 1px solid $grey !important;
}

.light-border span {
    color: $light-text;
}

.light-border:hover span {
    color: white;
}

.light-text {
    color: $light-text;
}

// Select box line
span.line {
    width: 1px;
    height: 36px;
    background: #ced4da;
    position: absolute;
    top: 29px;
    right: 44px;
    display: block;
}

.input-group-text {
    background-color: #ffffff00 !important;
}

.input-group .form-control {
    border-left: 0px !important;
    padding-left: 0px;
}

.fs-7 {
    font-size: 14px;
}

.fw-600 {
    font-weight: 600;
}

.fs-8 {
    font-size: 12px;
}

.phone-control {
    display: flex !important;
}

input.PhoneInputInput:focus {
    outline: none;
}

.form-control.phone-control.floating.PhoneInput {
    height: 58px;
}

span.passwordIcon.floating-icon {
    top: 20px;
}
.add-user-form {
    padding: 40px;
}