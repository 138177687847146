.cube-container {
  padding-top: 10%;
  perspective: 800px;
  width: 50px; /* Decreased size */
  height: 50px; /* Decreased size */
  position: relative;
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 25%; /* Center the cube vertically */
  left: 25%; /* Center the cube horizontally */
  transform-style: preserve-3d;
  animation: rotateCube 2s infinite linear; /* Basic cube rotation */
}

.face {
  position: absolute;
  width: 50px; /* Decreased size */
  height: 50px; /* Decreased size */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px; /* Decreased font size */
  color: white;
  background: rgb(146, 231, 19); /* Change background color to greenyellow */
  border: 2px solid rgb(230, 227, 227); /* Add green borders */
}

.face1 {
  transform: rotateY(0deg) translateZ(25px); /* Front face */
}

.face2 {
  transform: rotateY(90deg) translateZ(25px); /* Right face */
}

.face3 {
  transform: rotateY(180deg) translateZ(25px); /* Back face */
}

.face4 {
  transform: rotateY(-90deg) translateZ(25px); /* Left face */
}

.face5 {
  transform: rotateX(90deg) translateZ(25px); /* Top face */
}

.face6 {
  transform: rotateX(-90deg) translateZ(25px); /* Bottom face */
}

@keyframes rotateCube {
  100% {
    transform: rotateY(360deg); /* Rotate continuously */
  }
}
