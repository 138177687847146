.card-content-one {
    padding: 20px 30px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 4px #e5e5e5;
    border-radius: 8px;
}

.bold {
    font-weight: 650;
}

.semibold {
    font-weight: 500;
    color: #000000;
}

img.dashboard-img{
    height: 42px;
}

.search-bar{
    width: 80%;
    border: none;
}
.search{
    background-color: #FFFFFF;
    width: 100%;
    border: none;
    border-color: transparent;
}
.search:hover{
    border: none;
    border-color: transparent;
}
