.popupRes p span {
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
  }
  .popupRes p {
    margin: 0;
    padding: 0;
    font-size: 17px;
  }
  
  .quryCont {
    margin: 0;
    padding: 0;
  }
  .quryCont div {
    margin: 0;
    padding: 0;
  }
  .quryCont .column-2, .column-3  {
    font-size: 16px;
    font-weight: 600;
  }
  
  #modal-modal-title{
    font-weight: 600;
  
  }