@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Great+Vibes&family=Libre+Baskerville:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Open+Sans:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&family=Rubik:wght@300;400;500;600;700;800;900&family=Rye&family=Ysabeau+Infant:wght@1;100;200;300;400;500;600;700;800;900;1000&family=ZCOOL+QingKe+HuangYou&display=swap');

h1, h2, h3, h4, h5, h6
{
    font-family: 'Roboto';
}
.forget-link {
    text-decoration: none;
    display: block;
    margin-top: 10px;
}

.line {
    width: 137px;
}

.line-text {
    margin: 0px 17px;
    padding-top: 6px;
    font-size: 13px;
}

img.custom-logo {
    height: 96px;
    margin-bottom: 20px;
}
.relative
{
    position: relative;
}

.loginBGcol::after {
    content: "";
    background: #000;
    height: 120%;
    width: 100%;
    border-radius: 0 100% 100% 0;
    position: absolute;
    left: -8%;
    bottom: -14%;
    z-index: -1;
}
.left-login-cnt {
    padding: 70px;
}
.loginBGcol {
    overflow: hidden;
}

.login-right-col {
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form {
    margin-right: 70px;
}
.login-form {
    width: 100%;
}
.login-form form span.text-primary, a.forget-link {
    color: #000 !important;
}
.left-login-cnt p {
    padding-top: 20px;
}

button.btn.rounded-radius {
    border-radius: 40px;
}