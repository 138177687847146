.dashboard {
    color: #757575;
}

.dashboard:hover {
    background-color: #6CF102;
}

.logo {
    background-color: #FFFFFF;
}

a.menu-items.active {
    background: #6CF102;
    color: #fff;
}

img.dashboard-logo {
    height: 68px;
    width: auto;
}

.sider a.rs-sidenav-item-active span {
    color: #000 !important;
}

.sidebare .sider .rs-sidenav-item-active img {
    filter: brightness(0);
}

.hide-scroll{
scrollbar-width: none;
}

.countNotification {
    position: absolute;
    text-decoration: none;
    background-color: rgb(173, 49, 49);
    font-weight: 600;
    color: #fff;
    font-size: 11px;
    width: 20px;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    top: -1px;
    right: -5px;
    line-height: normal;
}

.notifies ul {
    padding: 0 !important;
    margin: 0 !important;
}

.notifies ul li {
    padding: 0 !important;
    margin: 0 !important;
}

.notifies.box-main ul li {
    padding: 0 !important;
    border-top: 1px solid #e9e9e9;
    padding: 15px 0 !important;
}

img.notify-icon {
    height: 18px;
    margin-left: 10px;
}

.notification-btn span {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
}

button.notification-btn {
    background: transparent !IMPORTANT;
    border: none !important;
}
.sidebaar::-webkit-scrollbar {
    width: 4px ;
}
.sidebaar::-webkit-scrollbar-thumb {
    background-color: #6cf102 ;
}
.notification-box ul.dropdown-menu {
    width: 100%;
    min-width: 400px;
    /* left: 0 !IMPORTANT; */
    right: 0 !important;
    margin: 0 auto !important;
    box-shadow: 0 4px 15px #ddd;
    border: none;
    padding: 0 0 20px 0 !important;
}

.notification-box ul.dropdown-menu li {
    border-bottom: 1px solid #eee;
    padding: 8px 0;
}
.f-12 {
    font-size: 12px;
}
