.dialog-boxes {
    padding: 30px 20px !important;
}

.btn-close {
    padding: 10px !important;
    border-radius: 50% !important;
    border: 1px solid #70707096 !important;
    opacity: 1 !important;
    background: transparent url("https://i.ibb.co/bdjRDMj/cbimage-1-1.png") center/1em auto no-repeat !important;
}