@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Great+Vibes&family=Libre+Baskerville:wght@400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Open+Sans:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&family=Rubik:wght@300;400;500;600;700;800;900&family=Rye&family=Ysabeau+Infant:wght@1;100;200;300;400;500;600;700;800;900;1000&family=ZCOOL+QingKe+HuangYou&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto' !important;
}

body {
  margin: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.f-16 {
  font-size: 16px;
}

.photo {
  width: 150px;
  height: 150px;
  border: 1px dashed lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-40 {
  font-size: 40px;
}

.f-14 {
  font-size: 14px;
}

.f-28 {
  font-size: 28px;
}

img.previewImage.setImage {
  border: none !important;
}

.setImage {
  object-fit: contain;
  padding: 2px;
}

.setImage {
  width: 150px !important;
  height: 150px !important;
  border: none !important;
}

.setplusImage {
  width: 50px !important;
  height: 50px !important;
  border: 100px !important;
}

.main-table table td button.btn.icon {
  border: none !important;
  padding: 0 !important;
}

.main-table table td button.btn.icon span.mx-2 {
  margin: 0 4px !important;
}

.main-table table td button.btn.icon:hover {
  color: #000 !important;
  background: transparent !important;
}


.sider a.light-menu.active,
.sidebare .sider .rs-sidenav-item-active {
  background: #6CF102 !important;
  border-radius: 12px 12px !important;
  color: #FFFFFF !important;
}

.sider ul ul a.rs-dropdown-item-active {
  color: #6cf102 !important;
}

img.menu-icon {
  height: 22px;
  width: 22px;
}

.sider a.light-menu {
  padding: 12px 0;
}

.sider a.light-menu:hover {
  color: var(--light) !important;
}

nav.sider.tras a img {
  height: auto;
  width: 22px;
}

.dash-img {
  display: flex;
  align-items: center;
  gap: 10px;
}

nav.sider.tras a {
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 3px 0 !important;
}

nav.sider.tras,
nav.sider.tras a {
  background-color: transparent !important;
  text-decoration: none;
  color: #FFFFFF !important;
  font-weight: 500;
  position: relative;
}

.sidebare .user-icon a::before {
  content: "";
  background: url(@/assets/setting.png);
  position: absolute;
  left: 14px;
  top: 16px;
  height: 22px;
  width: 22px;
  background-size: 22px;
}

.detail-col .driver-flex b {
  font-weight: 500 !important;
}

.sidebare .user-icon ul li a::before {
  display: none !important;
}

.sidebare .user-icon ul li {
  list-style: disc !important;
}

.sidebare .user-icon ul {
  padding-left: 40px !important;
}

.files-box {
  width: 434px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page.number,
.page {
  border: 1px solid #0000;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page.number.active {
  background: #6CF102;
  color: black;
}

.main-table table tbody tr {
  border-bottom: 1px solid #D8D8D8;
}

img.user-images {
  margin-top: 5px;
  height: 130px;
  width: 130px;
  object-fit: cover;
}
img.doc-images {
  height: 205px;
  width: auto;
  object-fit: contain;
  margin: auto;
}

div .colored-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: #36802d;
  background-color: #36802d;
  height: 11px;
  width: 11px;
}

div .red-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: red;
  background-color: red;
  height: 11px;
  width: 11px;
}

.driver-rghtt{
  color: #757575
}

.notification-box .dropdown-toggle::after {
  display: none;
}
.detail-col .driver-flex {
  justify-content: space-between;
}

.rdw-editor-main {
  padding: 20px;
}
.rdw-option-wrapper, .rdw-dropdown-wrapper {
  min-height: 26px !important;
  background: #f3f4f6 !important;
}

.rdw-dropdown-selectedtext {
  color: #000;
}

.rdw-editor-toolbar {
  background: #e5e7eb !important;
}

.append-calendar-to .flatpickr-calendar {
  top: -27px !important;
  left: 0px !important;
}

.view-ride .driver-rghtt {
  text-align: right;
}

.payout-details .driver-rghtt
{
  text-align: left;
}