.desc {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 500;
}

.date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-weight: 500;
}

.heading {
    border-bottom: 1px solid #eee;
}

.toper button {
    border: 1px solid #6CF102;
    color: #6CF102;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
    font-weight: 600;
}

.toper button:hover {
    background-color: #6CF102;
    color: #fff !important;
}